import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { delegate as tippyDelegate } from "tippy.js";
import * as Config from '@/config/constants';
import { debounce, getColorsForHours } from '@/app/helpers';
import { PublicHoliday, User } from '@/app/types/interfaces';
import Multiselect from 'vue-multiselect';
import ResourceSelect from '@/views/common/ResourceSelect.vue';
import GroupSelect from '@/views/common/GroupSelect.vue';
import { getProjectDays, getTotalsFromDays, renderSkills } from './staffing-helper';
import TimeModal from '@/views/time-modal/TimeModal.vue';


function getInitialStartDate() {
    return dayjs().subtract(1, 'week').toDate();
}
function getDaysToShow() {
    const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    return Math.round((viewportWidth - 300) / 40);
}


export default {
    name: 'Staffing',
    data() {
        return {
            startDate: getInitialStartDate(),
            hours: [],
            publicHolidays: [],
            selectedResource: null,
            selectedGroup: null,
            daysToShow: getDaysToShow(),
            projectHours: [],
            openedResources: [],
            selection: null,
            selectedProject: null,
        };
    },
    components: {
        ResourceSelect,
        GroupSelect,
        TimeModal,
        Multiselect,
    },
    computed: {
        // @ts-ignore
        ...mapGetters({
            resources: 'Resource/list/resources',
            groups: 'Group/edit/groups',
            projects: 'Project/list/projects',
            skills: 'Skill/skills',
        }),
        range() {
            return [this.startDate, dayjs(this.startDate).add(this.daysToShow - 1, 'day').toDate()];
        },
        formattedRange() {
            return this.range.map(d => dayjs(d).format(Config.DATE_FORMAT));
        },
        days() {
            const days = [];
            let currentDate = dayjs(this.range[0]);
            let currentMonth = -1;
            const lastDate = dayjs(this.range[1]).add(1, 'day');
            while (currentDate.isBefore(lastDate)) {
                const month = currentMonth !== currentDate.month() ? currentDate.format('MMMM YYYY') : null;
                const formatedDate = currentDate.format(Config.DATE_FORMAT);
                currentMonth = currentDate.month();
                const holidays = this.publicHolidays.filter((f: PublicHoliday) => f.date === formatedDate);
                days.push({
                    date: formatedDate,
                    weekend: currentDate.day() == 0 || currentDate.day() == 6,
                    number: currentDate.format('D'),
                    name: currentDate.format('ddd'),
                    month,
                    today: currentDate.isSame(dayjs(), 'day'),
                    holidays
                });
                currentDate = currentDate.add(1, 'day');
            }
            return days;
        },
        resourcesFiltered() {
            return this.resources.filter(r => {
                if (this.selectedResource) return r === this.selectedResource;
                if (this.selectedGroup) return this.selectedGroup.userIds.map(id => parseInt(id)).includes(r.id);
                return true;
            })
        },
        resourcesWrap() {
            return this.resourcesFiltered.map((r: User) => {
                const hours = this.hours.filter(h => parseInt(h.user_id) === r.id);
                const days = this.days.map(d => ({
                    day: d,
                    hours: hours.find(h => h.spent_on === d.date),
                    holiday: d.holidays.some((h: PublicHoliday) => h.country === r.country),
                }));
                const projectHours = this.projectHours.filter(ph => parseInt(ph.user_id) === r.id);
                return {
                    resource: r,
                    id: r.id,
                    days,
                    totals: getTotalsFromDays(days),
                    projects: getProjectDays(days, projectHours, this.projects),
                    open: this.openedResources.includes(r),
                };
            });
        },
        orderedSkills() {
            return this.skills.sort((a, b) => this.sortedCategories.indexOf(a.category) - this.sortedCategories.indexOf(b.category));
        },
    },
    methods: {
        getRangePayload() {
            return {
                from: this.formattedRange[0],
                to: this.formattedRange[1],
            };
        },
        loadHours() {
            const payload = this.getRangePayload();
            payload.userIds = this.resourcesFiltered.map(r => r.id);
            return this.$store.dispatch('TeamTime/staffingTime', payload);
        },
        loadProjectHours(resources) {
            if (!resources.length) return Promise.resolve([]);
            const payload = this.getRangePayload();
            payload.userIds = resources.map(r => r.id);
            payload.project = true;
            return this.$store.dispatch('TeamTime/staffingTime', payload);
        },
        loadPublicHolidays() {
            const payload = this.getRangePayload();
            return this.$store.dispatch('TimeEntry/list/getPublicHolidays', payload);
        },
        load() {
            Promise.all([
                this.loadHours(),
                this.loadProjectHours(this.openedResources),
                this.loadPublicHolidays(),
            ]).then(([hours, phHours, holidays]) => {
                this.hours = hours;
                this.projectHours = phHours;
                this.publicHolidays = holidays.public_holidays;
            });
        },
        today() {
            this.startDate = getInitialStartDate();
            this.refresh();
        },
        offsetPeriod(offset) {
            this.startDate = dayjs(this.startDate).add(offset * (this.daysToShow - 2), 'day').toDate();
            this.refresh();
        },
        getStyleForHours(hours) {
            if (!hours) return null;
            const backgroundColor = getColorsForHours(hours.hours, hours.leave_hours)
            const color = getColorsForHours(hours.hours, hours.leave_hours, 30, 80);
            return {
                backgroundColor,
                color,
            };
        },
        getStyleForProjectHours(hours) {
            const lc = (1 - (Math.min(hours, 8) / 8)) * 60;
            const lb = (1 - (Math.min(hours, 8) / 8)) * 30 + 60;
            return {
                color: `hsl(0, 0%, ${lc}%)`,
                backgroundColor: `hsl(0, 0%, ${lb}%)`
            };
        },
        getProjectRatio(resourceTotals, projectTotals) {
            return resourceTotals.ratio * projectTotals.hours / resourceTotals.hours;
        },
        updateViewport() {
            this.daysToShow = getDaysToShow();
            this.refresh();
        },
        refresh() {
            this.hours = [];
            this.projectHours = [];
            this.publicHolidays = [];
            this.load();
        },
        selectResource() {
            this.selectedGroup = null;
            this.$nextTick(() => {
                this.refresh();
            })
        },
        selectGroup() {
            this.selectedResource = null;
            this.$nextTick(() => {
                this.refresh();
            })
        },
        selectSlot(resourceId, projectId, day) {
            this.selection = {
                resourceId,
                projectId,
                date: day.day.date,
                hours: 8,
                issueId: day.hours ? day.hours.issue_id : null,
            };
        },
        toggleResource(resource) {
            const toggle = !this.openedResources.includes(resource);
            if (!toggle) {
                this.projectHours = this.projectHours.filter(ph => parseInt(ph.user_id) !== resource.id);
                this.openedResources = this.openedResources.filter(r => r !== resource);
                return;
            }
            this.loadProjectHours([resource]).then(data => {
                this.projectHours.push(...data);
                this.openedResources.push(resource);
            });
        },
        tipThat() {
            const vm = this;
            tippyDelegate('#staffing', {
                target: '.resource_row .resource',
                delay: 100,
                placement: 'right',
                onCreate(instance: any) {
                    instance._isRendered = false;
                },
                onShow(instance: any) {
                    if (instance._isRendered) return;
                    instance._isRendered = true;
                    const userId = instance.reference.dataset.resourceId;
                    vm.$store.dispatch('Skill/getUsersSkills', [userId]).then(usersSkills => {
                        const $skills = renderSkills(usersSkills, vm.skills);
                        // if (!$skills) return instance.destroy();
                        const htmlContent = `<h4 class="text-center">${vm.resources.find(r => r.id === parseInt(userId)).fullname}</h4>`;
                        instance.setContent(htmlContent + $skills);
                    });
                }
            });
        }
    },
    mounted() {
        this.$store.dispatch('Group/edit/getList');
        this.$store.dispatch('Project/list/getList');
        this.$store.dispatch('Skill/getList');
        this.refresh();
        window.addEventListener('resize', debounce(this.updateViewport, 500));
        this.tipThat();
    }
}
