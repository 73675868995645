import { TOOLTIP_MIN_SKILL_LVL } from "@/config/constants";

export function getTotalsFromDays(days) {
    const hoursAndDays = days.reduce((acc, d) => {
        const hours = parseFloat(d.hours?.hours) || 0;
        acc.days += (!d.day.weekend && !d.holiday) ? 1 : 0;
        acc.hours += hours;
        return acc;
    }, { hours: 0, days: 0 });
    return {
        hours: hoursAndDays.hours,
        days: hoursAndDays.days,
        ratio: hoursAndDays.hours / (hoursAndDays.days * 8)
    };
}

export function getProjectDays(days, hours, projectsAll) {
    if (!hours.length) return [];
    const projects = projectsAll.filter(p => hours.some(h => parseInt(h.project_id) === p.id))
    return projects.map(project => {
        const projectDays = days.map(d => ({
            day: d.day,
            hours: hours.find(h => parseInt(h.project_id) === project.id && h.spent_on === d.day.date),
            holiday: d.holiday,
        }));
        return {
            project,
            days: projectDays,
            totals: {
                hours: projectDays.reduce((acc, pd) => {
                    acc += pd.hours ? parseFloat(pd.hours.hours) : 0;
                    return acc
                }, 0)
            },
        }
    })
}

export function renderSkills(usersSkills, skills) {
    if (!usersSkills) return '';
    const realUserSkills = usersSkills.filter(userSkill => userSkill.level >= TOOLTIP_MIN_SKILL_LVL);
    if (!realUserSkills.length) return '';
    return '<u>Skills</u><br>' + realUserSkills.map(userSkill => {
        const stars = '&starf;'.repeat(userSkill.level) + '&star;'.repeat(5 - userSkill.level);
        const skill = skills.find(skill => skill.id == userSkill.skillId) || {};
        const name = skill.name;
        const $category = `<span class="opacity5">${skill.category}</span>`;
        return '<div>' + stars + ' ' + $category + ' ' + name + '</div>';
    }).join('');
}
